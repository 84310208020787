<template>
  <div class="app-container">
   <!-- todo -->
    <div class="top_btns">
      <div class="left_box">
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
        <el-input placeholder="请输入企业名称/编号" v-model="listQuery.name" style="width: 160px;margin-left:5px" class="filter-item" size="small" @keyup.enter="getList" clearable/>
        <el-button class="filter-item1" type="primary" size="small" @click="getList"   >
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 6px;width:405px" class="styleForm">
            <el-form-item label="选择人员 ：" :label-width="formLabelWidth">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:160px;margin-left: 0;"></selectuser>
            </el-form-item>
            <el-form-item label="纳税人类型 ：" :label-width="formLabelWidth">          
              <selecttaxtype v-model:type="listQuery.type" style="width:160px;margin-left: 0;"></selecttaxtype>
            </el-form-item> 
            <el-form-item label="税局 ：" :label-width="formLabelWidth"> 
              <selectcity v-model:citys="listQuery.districtCode" style="width:160px;margin-left: 0;"></selectcity>
            </el-form-item>
            <el-form-item label="报税手机号 ：" :label-width="formLabelWidth">          
              <el-input v-model.trim="listQuery.tel" size="small" style="width:160px;"></el-input>
            </el-form-item>
            <el-form-item label="授权状态 ：" :label-width="formLabelWidth">   
              <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:160px;margin-left: 0;"></selectAccreditStatus>
            </el-form-item>
            <el-form-item label="公司类型：" :label-width="formLabelWidth"> 
              <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型"  filterable clearable style="width:160px">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否零申报 ：" :label-width="formLabelWidth">          
              <el-radio-group v-model="listQuery.accountingType">
              <el-radio :label="3">全部</el-radio>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group> 
            </el-form-item>
            <el-form-item label="任务状态：" :label-width="formLabelWidth">
              <selectCjTaskStatus v-model:taskStatus="listQuery.taskStatus"></selectCjTaskStatus>
            </el-form-item>
            <el-form-item label="采集状态：" :label-width="formLabelWidth">
              <el-checkbox-group size="small" v-model="listQuery.businessStatus">
                <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value" @change="changeValue">{{item.label}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="有无税款 ：" :label-width="formLabelWidth">          
              <el-radio-group v-model="listQuery.taxStatus">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">有税款</el-radio>
                <el-radio :label="2">无税款</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <el-button size="small" type="primary" @click="declaration()" :disabled="!$buttonStatus('pljc_sjjc_jc')" :loading="jcLoading">
          <el-icon><Checked /></el-icon><span  > 批量采集</span>
        </el-button>
      </div>
    </div>
    <el-table stripe :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange" v-loading="listLoading">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column  label="编号" align="center" prop="sort" width="80" fixed="left">
        <template #default="scope">
          <TableSortCell :row="scope.row" idKey="comId" />
        </template>
      </el-table-column>
      <el-table-column align="left" prop="name" label="公司名称" min-width="240" class="gsmc">
        <template #default="scope">
          <TagNameCopy :row="scope.row"  idKey="comId"></TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column prop="accountingType" label="是否零申报" width="90">
        <template #default="scope">
          <span v-if="scope.row.accountingType == 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column> 
      <el-table-column  prop="district" label="税局" width="65">
        <template #default="scope">
          <span>{{ $cityFilter(scope.row.district)  }}</span>  
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="任务状态" min-width="180">
        <template #default="scope">
          <div class="div_p">
            <div class="item_icon">
              <p><i :class="$setUpStatusIconJc(scope.row.taskStatus, 'task')"></i>
                <el-tooltip :disabled="scope.row.errLog == ''" class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.errLog?scope.row.errLog:''"></div>
                  </template>
                  <p>{{$setUpStatusWsJc(scope.row.taskStatus, "task")}}<i class="iconfont icon-wenhao" v-if="scope.row.taskStatus == 4 && scope.row.errLog" style="color:#f56c6c;margin:0 0 0 4px;"></i></p>
                </el-tooltip>
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="采集状态" min-width="180">
        <template #default="scope">
          <div class="div_p" v-if="scope.row.taskStatus != '0' && scope.row.taskStatus != '' && scope.row.taskStatus != '1' && scope.row.taskStatus != '2'">
            <div class="item_icon">
              <p>
                <span v-if="scope.row.image" class="demo-image__preview" >
                  <i class="iconfont icon-pdf" style="color:red;font-size:16px;" v-if="(scope.row.bussinessStatus == 3)" @click="open(scope.row, scope.$index)"></i>
                  <i class="iconfont icon-pdf" style="color:#67c23a;font-size:16px;" v-if="(scope.row.bussinessStatus == 2)" @click="open(scope.row, scope.$index)"></i>
                  <i class="iconfont icon-pdf" style="color:#e6a23c;font-size:16px;" v-if="(scope.row.bussinessStatus == 30) || (scope.row.bussinessStatus == 50)" @click="open(scope.row, scope.$index)"></i>
                </span>
                <i v-if="!scope.row.image" :class="$setUpStatusIconJc(scope.row.bussinessStatus, 'business')"></i>
                <el-tooltip :disabled="scope.row.bussinessLog == ''" class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.bussinessLog?scope.row.bussinessLog:''"></div>
                  </template>
                  <span size="small" style="border: 0;" :class="$setUpStatusGsWordZf('5',scope.row.bussinessStatus)">{{$setUpStatusWsJc(scope.row.bussinessStatus, 'business')}}
                    <i class="iconfont icon-wenhao" v-if="scope.row.bussinessStatus != 2 && scope.row.bussinessLog" style="color:#f56c6c;"></i>
                  </span>
                </el-tooltip>
              </p>
              
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="manager" label="操作" min-width="180">
        <template #default="scope">
          <div class="div_p">
            <el-button @click="handleUpdate(scope.row)" size="small" type="text" :disabled="!$buttonStatus('pljc_sjjc_jc')">采集</el-button>
            <el-button :disabled="scope.row.taskStatus == 0" @click="declareDetail(scope.row)" size="small" type="text">采集记录</el-button>
          </div>
        </template>
      </el-table-column>
      
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="taxCheckProve"/>
    </div>
  </div>
  <sbImgUrl ref="sbImgUrl"></sbImgUrl>
  <declareDetails ref="declareDetails"></declareDetails>
  <settleHint />
  <el-dialog v-model="dialogFormVisible" title="查看详情" width="800px" destroy-on-close :close-on-click-modal="false">
    <el-table :data="checkList" style="width: 100%" border :height="contentStyleObj">
      <el-table-column align="left" label="税种" min-width="130" prop="name"></el-table-column>
      <el-table-column align="left" label="税额" min-width="130" prop="amount"></el-table-column>
      <el-table-column align="center" label="操作" min-width="180">
        <template #default="scope">
          <el-button type="primary" @click="checkUrl(scope.row)" size="small" plain icon="View">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible = false" size="small">关 闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { companyWsInitTaxList } from "@/api/company"
import selecttaxtype from "@/components/Screening/selecttaxtype";
import declareDetails from "./declareDetails"
import { currentAccountPeriod } from '@/utils'
import { sendTask } from "@/api/collection"
import sbImgUrl from "./sbImgUrl"
import settleHint from "./settleHint.vue";
import selectcity from "@/components/Screening/selectcity";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import selectCjTaskStatus from '../components/select/selectCjTaskStatus.vue'
import { COMPANY_TYPE_OPTION } from '@/utils/commonData.js'

export default {
  name: "taxCheck",
  components:{
    selecttaxtype,
    declareDetails,
    sbImgUrl,
    settleHint,
    selectcity,
    selectAccreditStatus,
    TagNameCopy,
    TableSortCell,
    selectCjTaskStatus
  },
  data(){
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      formLabelWidth:'100px',
      listQuery: {
        page: 1,
        limit: 20,
        taskName:"tax-cj-wszm",
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        businessStatus: [],
        allStatus: [],
        accountingType: 3,
        taxNames:[],
        status: 2,
        tel:"",
        companyType:'',
        taxStatus:0,
      },
      total:0,
      list:[],
      gsMainIds: undefined,
      pageStatus: true,
      listLoading:false,
      jcLoading:false,
      options: [
        {
          value: 2,
          label: '采集完成',
        },
        {
          value: 3,
          label: '采集失败',
        },
        {
          value: 40,
          label: '无需采集',
        },
      ],
      dialogFormVisible:false,
      checkList:[]
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created(){
    this.listQuery.limit = localStorage.getItem('taxCheckProve') ? localStorage.getItem('taxCheckProve')*1 : 20
    this.getList()
    this.contentStyleObj= this.$getHeight(190)
    this.initBus()
  },
  mounted(){
  },
  methods:{
    initBus(){
      if(!this.pageStatus){
          return
        }
        if(this.gsMainIds && this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
      this.$bus.on("refreshTask", () => {
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if ([1,2,99].includes(res.taskStatus)) {
            status = true;
          }
        })
        if(status == true) {
          companyWsInitTaxList(this.listQuery).then(res=>{
            this.list = res.data.data.list ? res.data.data.list : []
            this.total = res.data.data.total
          })
        }
      });
    },
    getList(){
      this.listLoading = true
      companyWsInitTaxList(this.listQuery).then(res=>{
        this.listLoading = false
        this.list = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
      })
    },
    open(row) {
      if(row.image.match(',')){
        let arr = row.image.split(',')
        let checkArr = []
        arr.map(v=>{
          let textArr = v.split('-')
          checkArr.push({
            name: textArr[0],
            amount:textArr[1],
            url:  textArr[2]
          })
        })
        this.checkList = checkArr
        this.dialogFormVisible = true
      }else{
        let textArr = row.image.split('-')
        window.open('https://file.listensoft.net/' + textArr[2])
      }
    },
    checkUrl(row){
      window.open('https://file.listensoft.net/' + row.url)
    },
    handleSelectionChange(row) {
      this.gsMainIds = []
      row.map(info=>{
        this.gsMainIds.push(info.comId)
      })
    },
    // 申报详情
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,e.taskName)
    },
    //批量申报
    declaration() {
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$confirm('确定要批量采集吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.jcLoading = true
        let param = {
          taskName: 'tax-cj-wszm',
          comIds: this.gsMainIds,
          period: this.listQuery.period
        }
        sendTask(param).then(res => {
          this.jcLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)

          }
        })
      })
    },
    //申报
    handleUpdate(e) {
      this.$confirm('确定要采集吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          taskName: 'tax-cj-wszm',
          comIds: [e.comId],
          period: this.listQuery.period
        }
        this.listLoading = true
        sendTask(param).then(res => {
          this.listLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)

          }
        })
      })
    },
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery = {
        page: 1,
        limit: originLimit,
        taskName:"tax-cj-wszm",
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        businessStatus: [],
        allStatus: [],
        accountingType: 3,
        taxNames:[],
        status: 2,
        tel:"",
        companyType:'',
        taxStatus:0,
      }
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.filter-item1 {
  margin-right: 10px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }

  p{
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
</style>