<template>
   <el-dialog :close-on-click-modal="false"
    v-model="dialogFormVisible"
    :title="name"
    width="90%"
    destroy-on-close
    >

  <div v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 中华人民共和国企业所得税年度纳税申报表（A类） -->
    <div class="big_box4">
     <el-scrollbar :height="contentStyleObj">
      <div class="top_title3">
        <h4>中华人民共和国企业所得税年度纳税申报表（A类）</h4>
        <!-- <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span>2020-2-25</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div> -->
      </div>
      <table class="content" cellspacing="0" width="100%">
        <tr class="top_bg">
         <td class="center" style="width:4%;">行次</td>
         <td class="center" style="width:8%;">类别</td>
         <td class="center" style="width:68%;">项目</td>
         <td class="center" style="width:20%;">金额</td>
        </tr>
      </table>
      <div class="fixed_box_b">
        <table class="content" cellspacing="0">
          <tr>
            <td class="center" style="width:4%;">1</td>
            <td class="center" rowspan= '13' style="width:8%;">利润总额计算</td>
            <td style="width:68%;">一、营业收入(填写A101010\101020\103000)</td>
            <!-- <qzf-str-td class="blue_box" style="width:20%" :disabled="false" v-model:amount="info[0].amount"></qzf-str-td> -->
            <td class="blue_box" style="width:20%;"><input type="text" v-model="info[0].amount"></td>
          </tr>
          <tr>
            <td class="center">2</td>
            <td class="left_k">减：营业成本(填写A102010\102020\103000)</td>
            <!-- <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[1].amount"></qzf-str-td> -->
            <td class="blue_box"><input type="text" v-model="info[1].amount"></td>
          </tr>
          <tr>
            <td class="center">3</td>
            <td class="left_k">减：税金及附加</td>
            <td class="blue_box"><input type="text" v-model="info[2].amount"></td>
          </tr>
          <tr>
            <td class="center">4</td>
            <td class="left_k">减：销售费用(填写A104000)</td>
            <td class="blue_box"><input type="text" v-model="info[3].amount"></td>
          </tr>

          <tr>
            <td class="center">5</td>
            <td class="left_k">减：管理费用(填写A104000)</td>
            <td class="blue_box"><input type="text" v-model="info[4].amount"></td>
          </tr>

          <tr>
            <td class="center">6</td>
            <td class="left_k">减：财务费用(填写A104000)</td>
            <td class="blue_box"><input type="text" v-model="info[5].amount"></td>
          </tr>
          <tr>
            <td class="center">7</td>
            <td class="left_k">减：资产减值损失</td>
            <td class="blue_box"><input type="text" v-model="info[6].amount"></td>
          </tr>
          <tr>
            <td class="center">8</td>
            <td class="left_k">加：公允价值变动收益</td>
            <td class="blue_box"><input type="text" v-model="info[7].amount"></td>
          </tr>
          <tr>
            <td class="center">9</td>
            <td class="left_k">加：投资收益</td>
            <td class="blue_box"><input type="text" v-model="info[8].amount"></td>
          </tr>
          <tr>
            <td class="center">10</td>
            <td>二、营业利润(1-2-3-4-5-6-7+8+9)</td>
            <td class="blue_box"><input type="text" v-model="info[9].amount"></td>
          </tr>
          <tr>
            <td class="center">11</td>
            <td class="left_k">加：营业外收入(填写A101010\101020\103000)</td>
            <td class="blue_box"><input type="text" v-model="info[10].amount"></td>
          </tr>
          <tr>
            <td class="center">12</td>
            <td class="left_k">减：营业外支出(填写A102010\102020\103000)</td>
            <td class="blue_box"><input type="text" v-model="info[11].amount"></td>
          </tr>
          <tr>
            <td class="center">13</td>
            <td>三、利润总额（10+11-12）</td>
            <td class="blue_box"><input type="text" v-model="info[12].amount"></td>
          </tr>


          <tr>
            <td class="center">14</td>
            <td class="center" rowspan= '10'>应纳税所得额计算</td>
            <td>减：境外所得（填写A108010）</td>
            <td class="blue_box"><input type="text" v-model="info[13].amount"></td>
          </tr>
          <tr>
            <td class="center">15</td>
            <td class="left_k">加：纳税调整增加额（填写A105000）</td>
            <td class="blue_box"><input type="text" v-model="info[14].amount"></td>
          </tr>
          <tr>
            <td class="center">16</td>
            <td class="left_k">减：纳税调整减少额（填写A105000）</td>
            <td class="blue_box"><input type="text" v-model="info[15].amount"></td>
          </tr>

          <tr>
            <td class="center">17</td>
            <td class="left_k">减：免税、减计收入及加计扣除（填写A107010）</td>
            <td class="blue_box"><input type="text" v-model="info[16].amount"></td>
          </tr>
          <tr>
            <td class="center">18</td>
            <td class="left_k">加：境外应税所得抵减境内亏损（填写A108000）</td>
            <td class="blue_box"><input type="text" v-model="info[17].amount"></td>
          </tr>
          <tr>
            <td class="center">19</td>
            <td>四、纳税调整后所得（13-14+15-16-17+18）</td>
            <td class="blue_box"><input type="text" v-model="info[18].amount"></td>
          </tr>
          <tr>
            <td class="center">20</td>
            <td class="left_k">减：所得减免（填写A107020）</td>
            <td class="blue_box"><input type="text" v-model="info[19].amount"></td>
          </tr>
          <tr>
            <td class="center">21</td>
            <td class="left_k">减：弥补以前年度亏损（填写A106000）</td>
            <td class="blue_box"><input type="text" v-model="info[20].amount"></td>
          </tr>
          <tr>
            <td class="center">22</td>
            <td class="left_k">减：抵扣应纳税所得额（填写A107030）</td>
            <td class="blue_box"><input type="text" v-model="info[21].amount"></td>
          </tr>
          <tr>
            <td class="center">23</td>
            <td>五、应纳税所得额（19-20-21-22）</td>
            <td class="blue_box"><input type="text" v-model="info[22].amount"></td>
          </tr>

          <tr>
            <td class="center">24</td>
            <td class="center" rowspan= '13'>应纳税额计算</td>
            <td>税率（25%）</td>
            <td class="gary_box">{{info[23].amount}}</td>
          </tr>
          <tr>
            <td class="center">25</td>
            <td>六、应纳所得税额（23×24）</td>
            <td class="blue_box"><input type="text" v-model="info[24].amount"></td>
          </tr>
          <tr>
            <td class="center">26</td>
            <td class="left_k">减：减免所得税额（填写A107040）</td>
            <td class="blue_box"><input type="text" v-model="info[25].amount"></td>
          </tr>
          <tr>
            <td class="center">27</td>
            <td class="left_k">减：抵免所得税额（填写A107050）</td>
            <td class="blue_box"><input type="text" v-model="info[26].amount"></td>
          </tr>
          <tr>
            <td class="center">28</td>
            <td>七、应纳税额（25-26-27）</td>
            <td class="blue_box"><input type="text" v-model="info[27].amount"></td>
          </tr>
          <tr>
            <td class="center">29</td>
            <td class="left_k">加：境外所得应纳所得税额（填写A108000）</td>
            <td class="blue_box"><input type="text" v-model="info[28].amount"></td>
          </tr>
          <tr>
            <td class="center">30</td>
            <td class="left_k">减：境外所得抵免所得税额（填写A108000）</td>
            <td class="blue_box"><input type="text" v-model="info[29].amount"></td>
          </tr>
          <tr>
            <td class="center">31</td>
            <td>八、实际应纳所得税额（28+29-30）</td>
            <td class="blue_box"><input type="text" v-model="info[30].amount"></td>
          </tr>
          <tr>
            <td class="center">32</td>
            <td class="left_k">减：本年累计实际已缴纳的所得税额</td>
            <td class="blue_box"><input type="text" v-model="info[31].amount"></td>
          </tr>
          <tr>
            <td class="center">33</td>
            <td>九、本年应补（退）所得税额（31-32）</td>
            <td class="blue_box"><input type="text" v-model="info[32].amount"></td>
          </tr>
          <tr>
            <td class="center">34</td>
            <td class="left_k">其中：总机构分摊本年应补（退）所得税额(填写A109000)</td>
            <td class="blue_box"><input type="text" v-model="info[33].amount"></td>
          </tr>
          <tr>
            <td class="center">35</td>
            <td class="left_indent">财政集中分配本年应补（退）所得税额（填写A109000）</td>
            <td class="blue_box"><input type="text" v-model="info[34].amount"></td>
          </tr>
          <tr>
            <td class="center">36</td>
            <td class="left_indent">总机构主体生产经营部门分摊本年应补（退）所得税额(填写A109000)</td>
            <td class="blue_box"><input type="text" v-model="info[35].amount"></td>
          </tr>
          <tr>
            <td class="center">FZ1</td>
            <td class="center" rowspan= '10'>实际缴纳企业所得税计算</td>
            <td>中央级收入实际应补（退）所得税额（一般企业33x60%；总机构(34+36)*60%+35）</td>
            <td class="blue_box"><input type="text" v-model="info[36].amount"></td>
          </tr>
          <tr>
            <td class="center">FZ2</td>
            <td>地方级收入应补（退）所得税额（一般企业33x40%；总机构(34+36)*40%）</td>
            <td class="blue_box"><input type="text" v-model="info[37].amount"></td>
          </tr>
          <tr>
            <td class="center">37</td>
            <td>本年民族自治地区地方分享部分优惠方式</td>
            <td class="blue_box">
              <el-radio-group v-model="info[38].amount" size="small">
                <el-radio label="免征">免征</el-radio>
                <el-radio label="减征">减征</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
              <!-- <input type="text" v-model="info[38].amount"> -->
            </td>
          </tr>
          <tr>
            <td class="center">37.0</td>
            <td>优惠幅度（0为不减免，100%为免征）</td>
            <td class="blue_box"><input type="text" v-model="info[39].amount"></td>
          </tr>
          <tr>
            <td class="center">37.1</td>
            <td>本年应减免金额（一般企业31行x40%x37.0行“优惠幅度”；总机构A109000表第18行）</td>
            <td class="blue_box"><input type="text" v-model="info[40].amount"></td>
          </tr>
          <tr>
            <td class="center">37.2</td>
            <td>本年累计已减免金额（本年度4季度预缴申报表23.1行，总机构A109000表第19行）</td>
            <td class="blue_box"><input type="text" v-model="info[41].amount"></td>
          </tr>
          <tr>
            <td class="center">37.3</td>
            <td>因优惠产生的地方级收入应补（退）金额（一般企业37.1-37.2；总机构A109000表第20行）</td>
            <td class="blue_box"><input type="text" v-model="info[42].amount"></td>
          </tr>
          <tr>
            <td class="center">37.4</td>
            <td>总机构及分支机构地方级收入全年减免总额（37.1+A109010第12列合计）</td>
            <td class="blue_box"><input type="text" v-model="info[43].amount"></td>
          </tr>
          <tr>
            <td class="center">FZ3</td>
            <td>地方级收入实际应补（退）所得税额（FZ2-37.3）</td>
            <td class="blue_box"><input type="text" v-model="info[44].amount"></td>
          </tr>
          <tr>
            <td class="center">38</td>
            <td>十、实际应补（退）所得税额（一般企业FZ1+FZ3;总机构A109000第21行）</td>
            <td class="blue_box"><input type="text" v-model="info[45].amount"></td>
          </tr>
        </table>
      </div>
      </el-scrollbar>
      <div class="save_box">
        <el-button type="primary" size="small" @click="saveDetail()" :disabled="!$buttonStatus('hsqj_bbbc')"><i class="iconfont icon-baocun"></i>保存</el-button>
      </div>
    </div>
    
    <!-- 中华人民共和国企业所得税年度纳税申报表（A类） end -->
  </div>
</el-dialog>

</template>
<script>
import { yearInfo, yearEdit } from "@/api/year"
export default {
  name: "A100000",
  props: {
    tableYearName: {
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
    name:{
      default:'',
      type:String
    }
  },
  data() {
    return {
      comName: '',
      taxNo: '',
      info:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      fullscreenLoading:false,
      query:undefined,
      dialogFormVisible:false,
      contentStyleObj:{}
    };
  },
  created() {
    this.comName = this.$store.getters['user/comInfo'].comName
    this.taxNo = this.$store.getters['user/comInfo'].taxNo
  },
  mounted(){
    this.contentStyleObj = this.$getHeight(230)
  },
  methods: {
    getParams() {
      this.dialogFormVisible = true
      // const tableName = this.$route.params.tableName;
      const tableName = 'year_a100000'
      this.tableName = tableName
      if (tableName) {
        this.getDetail({comId:this.comId,period:this.startAccountPeriod,tableName:this.tableYearName});
        this.listLoading = true;
      } else {
        this.listLoading = false;
      }
    },
    getDetail(e) {
      yearInfo(e).then(response => {
        if(response.data.data && response.data.data.length == 46){
          this.info = response.data.data;
        }else{
          this.$qzfMessage('税局更新，请重新取数',1)
        }
      });
    },
    // 点击保存
    saveDetail(){
      this.fullscreenLoading = true
      yearEdit({comId:this.comId,year:this.startAccountPeriod,tableName:this.tableName,items:this.info}).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            message: "保存成功",
            type: "success"
          });
        }
        this.fullscreenLoading = false
        this.getDetail({comId:this.comId,period:this.startAccountPeriod,tableName:this.tableYearName})
      });
    }
    
  }
};
</script>

<style lang="scss" scoped>
.border_none .el-select{
  width: 100% !important;
}
.center{
  text-align: center;
}
.border_none .el-input__inner{
  width: 100% !important;
  background-color: none !important;
}

.el-radio+.el-radio {
    margin-left: 7px;
}
.el-radio__label{
  font-size: 12px !important;
}
.input_width input{
  width:60px !important;
  margin: 0 5px;
}
.width_big{
  width: 30%;
}
.width_small{
  width: 20%;
}
.width_medium{
  width: 18%;
}
.width_ss{
  width: 15%;
}
.width_xs{
  width:13%;
}
.gary_box{
  background: #f8f8f8;
  text-align: right;
  padding-right: 20px !important;
}
.blue_box{
  background: #EBF5FF ;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #666;
    font-size: 12px;
  }
  input{
    width: 100%;
    line-height: 28px;
    // padding: 0 6px;
    text-align: right;
    font-size: 12px;
  }
}
.left_k{
  text-indent: 2em;
}
.left_indent{
  text-indent: 5em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  background: #fff;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>



